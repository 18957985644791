import { NgxLoggerLevel } from 'ngx-logger';
import { commons } from './environment.commons';

export const environment = {
  commons,
  loggerLevel: NgxLoggerLevel.DEBUG,
  production: false,
  urlDBFirebase: '/instore/notification/dev',
  urlDBFirebasePostSale: '/instore/postsale/dev',
  urlDBFirebaseQueueManager: '/instore/queue-manager/dev',
  firebaseKeys: {
    apiKey: "AIzaSyA8pameJ4hr7sAQ3yOYbezTizVfmrhSQUk",
    authDomain: "pe-gcp-customercare-02.firebaseapp.com",
    databaseURL: "https://instore-notification.firebaseio.com",
    projectId: "pe-gcp-customercare-02",
    storageBucket: "pe-gcp-customercare-02.appspot.com",
    messagingSenderId: "52415902569",
    appId: "1:52415902569:web:00f579c663279a9d2743f8",
    measurementId: "G-8CT13VRMZB"
  },
  firebaseProvider: {
    name: 'microsoft.com',
    prompt: "consent",
    tenant: "5daabe65-ff53-4336-a448-2f61ecf0b059",
    authority: 'https://login.microsoftonline.com/common',
    scope: 'mail.read'
  },
  system: 'INSTORE',
  userBasicAuthenticationOrderAttention: 'usuario',
  keyBasicAuthenticationOrderAttention: 'password',
  ApiLogin: 'https://login-dev.cc.cloudintercorpretail.pe/api/customer-care/auth',
  ApiInstore: 'https://instore-dev.cc.cloudintercorpretail.pe/api/customer-care',
  bucketname: 'instore-files-quality',
  pathRoot: 'development/order-attention',
  ApiFiles: 'https://files-dev.cc.cloudintercorpretail.pe/api/customer-care',
  ApiOrderAttention: 'https://order-attention-dev.cc.cloudintercorpretail.pe/api/customer-care/v1',
  ApiReportAttention: 'https://report-attention-dev.cc.cloudintercorpretail.pe',
  ApiReportInstore: 'https://report-instore-dev.cc.cloudintercorpretail.pe',
  ApiValidateToken: 'https://cc-irma-deliver-order-dev.cc.cloudintercorpretail.pe/api/pickup-order',
  ApiOrders: 'https://order-delivery-dev.cc.cloudintercorpretail.pe',
  ApiOrderAttentionMobile: 'https://api-instore-dev.cc.cloudintercorpretail.pe/v2',
  ApiPushNotification: 'https://movile-notification-dev.cc.cloudintercorpretail.pe/api/customer-care/v1/send/notification',
  ApiCustomer: 'https://customers-prd.cc.cloudintercorpretail.pe/customers'
};